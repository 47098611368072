.button {
  display: inline-flex;
  z-index: 1;
  align-items: center;
  padding: 0.34rem 1.2rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  background-color: transparent;
  position: relative;
  border: 0;
  text-decoration: none;
  overflow: hidden;
  border-radius: 5px;
  color: var(--primary-color-dark);
  transition: transform 0.2s, background 0.3s, box-shadow 0.3s, color 0.3s;

  > span {
    z-index: 2;
  }

  > svg + span {
    margin-left: 0.25rem;
  }

  &:hover {
    box-shadow: var(--glow);
    transform: translateY(-1px);
  }

  &:after {
    content: ' ';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    border: 1px solid currentColor;
    border-radius: 5px;
    transition: opacity 0.3s, box-shadow 0.3s;
    opacity: 0.6;
    box-shadow: inset -1px -2px 0px 0px rgba(0, 0, 0, 0.05);
  }

  &:active:after {
    box-shadow: none;
  }

  &:hover:after {
    opacity: 1;
  }

  &.primary {
    color: #fff;
    background-color: var(--primary-color-dark);

    &:after {
      border-color: rgba(0, 0, 0, 0.1);
    }

    &:before {
      content: '';
      z-index: -1;
      position: absolute;
      bottom: 100%;
      right: 100%;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: var(--primary-color);
      transform-origin: center;
      transform: translate(50%, 50%) scale(0);
      transition: transform 0.2s ease-in-out;
    }

    &:focus:before,
    &:hover:before {
      transform: translate(50%, 50%) scale(20);
    }
  }

  &--small {
    font-size: 0.85rem;
    padding: 0.35rem 0.7rem;
  }
  &--xsmall {
    font-size: 0.7rem;
    padding: 0.3rem 0.7rem;
  }

  &--icon {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  &--blank {
    background-color: transparent;
    border-color: transparent;
    padding-left: 0;
    padding-right: 0;
    box-shadow: none !important;

    &:not(:hover) {
      color: currentColor;
      opacity: 0.6;
    }
    &:after {
      display: none;
    }
  }

  &--large {
    font-size: 1.2rem;
    padding: 0.5rem 1.5rem;
  }
}
