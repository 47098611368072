.container {
  max-width: 1300px;
  position: relative;

  &,
  &-md,
  &-full,
  &-base,
  &-sm,
  &-mini {
    padding: 0 var(--space);
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  &-md {
    max-width: 760px;
  }
  &-base {
    max-width: 680px;
  }
  &-sm {
    max-width: 630px;
  }
  &-mini {
    max-width: 460px;
  }

  &--push {
    margin-left: -15px;
    margin-right: -15px;
  }

  &--framed {
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
    padding: var(--space-x2);
    background-color: #fff;
  }
}

.container-main {
  flex-direction: column;
  flex-wrap: nowrap;

  @media screen and (min-width: 850px) {
    flex-direction: row;
  }
}
