a {
  transition: color 0.3s, opacity 0.3s, background 0.3s, text-decoration 0.5s;
  position: relative;
  text-decoration: none;
  color: var(--primary-color);
  text-decoration-color: var(--border-color);

  &:hover {
    color: var(--body-color);
    text-decoration-color: var(--body-color);
  }

  svg + & {
    margin-left: 0.5rem;
  }

  code & {
    color: currentColor;
    font-weight: bold;
  }
}

a:hover:not(.active) {
  opacity: 1;
}
