button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

input {
  width: 100%;
  padding: 0.34rem 0.8rem;
  border-radius: 5px;
  color: var(--body-color);
  border: 0;
  transition: background 0.3s, box-shadow 0.3s;
  background-color: var(--bg-teritary);

  &:focus {
    outline: 0;
    box-shadow: inset 0 0 0 1px var(--bg-transparent);
  }
}
