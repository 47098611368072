:root {
  --body-color: #ebf4f1;
  --bg-secondary: #202427;
  --bg-teritary: #2d343a;
  --bg-transparent: rgba(0, 0, 0, 0.5);
  --bg: black;
  --border-color-darker: #22303b;
  --border-color: #2f3b46;
  --glow: 2px 2px 15px 0 #121b22;
  --inline-code-bg: rgba(212, 53, 159, 0.1);
  --inline-code-text: #e24cb2;
  --primary-color-dark: #72a5c4;
  --primary-color: #72a5c4;
  --header-height: 58px;
  --inline-code-bg: rgba(212, 53, 159, 0.03);
  --code-bg: #0e2233;
  --code-color: #fdf9f3;
  --dark-bg: #23303a;
  --inline-code-text: #b32585;
  --mark-color: #fff8ec;
  --select-color: rgba(0, 0, 0);
  --space: 25px;
  --space-x2: 50px;
  --success-bg: #5bb19640;
}
@media screen and (max-width: 550px) {
  :root {
    --space: 20px;
    --space-x2: 20px;
  }
}

.sidebar {
  z-index: 10;
  overflow: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
  padding: 4% 20px 0 5px;
}
@media screen and (min-width: 1189px) {
  .sidebar {
    border-right: 1px solid var(--border-color);
    position: sticky;
    transition: border-color 0.3s;
    top: var(--header-height);
    width: 260px;
  }
}
@media screen and (max-width: 1188px) {
  .sidebar {
    position: relative;
    margin: 0;
    order: 2;
    padding: calc(2% + var(--space)) 0px calc(2% + var(--space)) 25px;
  }
  .sidebar + .section {
    margin-left: 0;
    width: 100%;
  }
}
.sidebar--right {
  border-right: 0;
  width: 290px;
  position: sticky;
}
.sidebar h3 {
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 15px 0 15px;
  padding-top: 20px;
  border-top: 1px solid var(--border-color);
}
.sidebar h3:first-of-type {
  border: 0;
}
.sidebar p {
  margin-bottom: 5px;
  font-size: 0.9rem;
}
.sidebar a {
  color: currentColor;
  opacity: 1;
  display: flex;
  text-decoration: none;
}
.sidebar a.active--exact {
  font-weight: 400;
  color: var(--primary-color-dark);
}
.sidebar a:hover {
  color: var(--primary-color);
}
.sidebar .menu-link {
  margin: 0;
  padding: 0.2rem 0;
  font-size: 0.95rem;
}
.sidebar .menu-link.active {
  color: var(--primary-color);
  position: relative;
  padding-left: 0.8rem 0;
}
.sidebar .submenu {
  list-style: none;
  margin: 0;
  font-size: 0.9rem;
  opacity: 1;
}
.sidebar .submenu__item-depth-2 {
  margin-bottom: 0;
  padding: 0.4rem 0;
  font-size: 0.85rem;
  border-top: 1px dashed var(--border-color);
  transition: border-color 0.3s;
}
.sidebar .submenu__item-depth-3 {
  margin-bottom: 0;
  margin-top: -0.4rem;
  padding: 0.2rem 0.4rem;
  font-size: 0.8rem;
  opacity: 0.8;
}
.sidebar.sidebar--right a {
  margin: 0;
  padding: 0.2rem 0;
  font-size: 0.95rem;
}
.sidebar.sidebar--right a.active {
  color: var(--primary-color);
  position: relative;
  padding-left: 0.8rem 0;
}

nav a {
  letter-spacing: 0.5px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 15px 5px;
  text-decoration: none;
  position: relative;
  font-size: 0.95rem;
}
@media screen and (max-width: 850px) {
  nav a {
    padding-left: 2px;
    padding-right: 2px;
  }
}
nav a svg {
  margin: 2px 6px;
}
nav a:not(.active):not(:hover):not(.button) {
  color: currentColor;
  opacity: 0.85;
}
nav a:hover {
  color: var(--primary-color);
}
nav a:hover:after {
  background-color: var(--primary-color);
}
nav a.active {
  color: var(--primary-color);
}
nav a.active:after {
  background-color: var(--primary-color);
  width: 100%;
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  position: fixed;
  right: 0;
}

.sidebar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  transition: background-color 2s;
}

.sidebar::-webkit-scrollbar-thumb:hover,
.sidebar:hover::-webkit-scrollbar-thumb {
  background: var(--bg-teritary);
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

input {
  width: 100%;
  padding: 0.34rem 0.8rem;
  border-radius: 5px;
  color: var(--body-color);
  border: 0;
  transition: background 0.3s, box-shadow 0.3s;
  background-color: var(--bg-teritary);
}
input:focus {
  outline: 0;
  box-shadow: inset 0 0 0 1px var(--bg-transparent);
}

body,
html {
  background-color: var(--bg);
  transition: background-color 0.3s;
  margin: 0;
  font-family: "Jost";
  color: var(--body-color);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body *:target {
  animation: target 2s;
}

@keyframes target {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
img,
svg {
  vertical-align: middle;
  max-width: 100%;
}

hr {
  border: 1px solid var(--border-color);
  background-color: var(--border-color) !important;
}

::selection {
  background: var(--select-color);
}

.container {
  max-width: 1300px;
  position: relative;
}
.container, .container-md, .container-full, .container-base, .container-sm, .container-mini {
  padding: 0 var(--space);
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.container-md {
  max-width: 760px;
}
.container-base {
  max-width: 680px;
}
.container-sm {
  max-width: 630px;
}
.container-mini {
  max-width: 460px;
}
.container--push {
  margin-left: -15px;
  margin-right: -15px;
}
.container--framed {
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  padding: var(--space-x2);
  background-color: #fff;
}

.container-main {
  flex-direction: column;
  flex-wrap: nowrap;
}
@media screen and (min-width: 850px) {
  .container-main {
    flex-direction: row;
  }
}

.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.flex > *:last-child {
  margin-left: 0;
  margin-right: 0;
}
.flex--center {
  justify-content: center;
}
.flex--align-equal {
  align-items: stretch;
}
.flex--align-equal > * {
  align-self: stretch;
}
.flex--no-wrap {
  flex-wrap: no-wrap;
}
.flex--space-between {
  justify-content: space-between;
}

.gap-15 > * {
  margin-right: 15px;
}
.gap-20 > * {
  margin-right: 20px;
}
.gap-30 > * {
  margin-right: 30px;
}
.gap-60 > * {
  margin-right: 60px;
}

.flex-fit {
  flex: 1;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-align-top {
  align-items: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.button {
  display: inline-flex;
  z-index: 1;
  align-items: center;
  padding: 0.34rem 1.2rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  background-color: transparent;
  position: relative;
  border: 0;
  text-decoration: none;
  overflow: hidden;
  border-radius: 5px;
  color: var(--primary-color-dark);
  transition: transform 0.2s, background 0.3s, box-shadow 0.3s, color 0.3s;
}
.button > span {
  z-index: 2;
}
.button > svg + span {
  margin-left: 0.25rem;
}
.button:hover {
  box-shadow: var(--glow);
  transform: translateY(-1px);
}
.button:after {
  content: " ";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  border: 1px solid currentColor;
  border-radius: 5px;
  transition: opacity 0.3s, box-shadow 0.3s;
  opacity: 0.6;
  box-shadow: inset -1px -2px 0px 0px rgba(0, 0, 0, 0.05);
}
.button:active:after {
  box-shadow: none;
}
.button:hover:after {
  opacity: 1;
}
.button.primary {
  color: #fff;
  background-color: var(--primary-color-dark);
}
.button.primary:after {
  border-color: rgba(0, 0, 0, 0.1);
}
.button.primary:before {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 100%;
  right: 100%;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: var(--primary-color);
  transform-origin: center;
  transform: translate(50%, 50%) scale(0);
  transition: transform 0.2s ease-in-out;
}
.button.primary:focus:before, .button.primary:hover:before {
  transform: translate(50%, 50%) scale(20);
}
.button--small {
  font-size: 0.85rem;
  padding: 0.35rem 0.7rem;
}
.button--xsmall {
  font-size: 0.7rem;
  padding: 0.3rem 0.7rem;
}
.button--icon {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}
.button--blank {
  background-color: transparent;
  border-color: transparent;
  padding-left: 0;
  padding-right: 0;
  box-shadow: none !important;
}
.button--blank:not(:hover) {
  color: currentColor;
  opacity: 0.6;
}
.button--blank:after {
  display: none;
}
.button--large {
  font-size: 1.2rem;
  padding: 0.5rem 1.5rem;
}

.dots-bg {
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 14px;
}
.dots-bg--animated {
  animation: animatedDots 0.6s linear infinite;
}

.section {
  position: relative;
  width: 100%;
  flex: 1;
}
.section--secondary {
  background-color: var(--bg-secondary);
}
.section--secondary + .section--secondary {
  border-top-color: transparent;
  margin-top: -1px;
}
.section__dots-bg {
  height: 700px;
  max-width: 1500px;
  max-height: 100%;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 1;
}
.section--inner {
  position: relative;
  z-index: 2;
}
.section--dark {
  color: #fff;
  background: var(--dark-bg);
}
.section--dark p {
  color: currentColor;
}
.section--dark h1,
.section--dark h2,
.section--dark h3,
.section--dark h4,
.section--dark a {
  color: #fff;
}

.text-center {
  text-align: center;
}

.no-wrap {
  white-space: no-wrap;
  flex-wrap: no-wrap;
  display: inline-block;
}

.mb {
  margin-bottom: var(--space) !important;
}

.mb-x2 {
  margin-bottom: var(--space-x2) !important;
}

.pb {
  padding-bottom: var(--space) !important;
}

.ml {
  margin-left: 10px !important;
}

.show-for-small {
  display: none;
}

@media screen and (max-width: 450px) {
  .hide-for-small {
    display: none !important;
  }
  .show-for-small {
    display: inherit;
  }
}
::selection {
  background: var(--select-color);
}

.border-top {
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, #272b36 0%, #a2a7aa 50%, #72a5c4 100%);
}

.card {
  height: 100%;
  border: 1px solid var(--border-color-darker);
  border-radius: 4px;
  background-color: var(--bg);
  transition: color 0.3s, box-shadow 0.3s, transform 0.3s, background-color 0.3s, border-color 0.3s;
  position: relative;
  z-index: 1;
}
.card__title {
  text-align: center;
  width: 100%;
  margin-top: -16px;
}
.card__title span {
  display: inline-block;
  padding: 2px 12px 4px;
  background-color: var(--dark-bg);
  color: #fff;
  border-radius: 3px;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.5px;
}
.card__image {
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  border-bottom: 1px solid var(--border-color);
  transition: border-color 0.3s;
}
.card__image img {
  margin: 0;
  width: 100%;
}
.card__inner {
  padding: var(--space);
  overflow: hidden;
  position: relative;
}
.card[class*=container] .card__inner {
  padding-left: 0;
  padding-right: 0;
}
.section--dark .card {
  background-color: var(--dark-bg);
  border-color: rgba(255, 255, 255, 0.1);
}
.card__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  text-indent: -9999px;
  z-index: 1;
}
.card__link:focus {
  opacity: 1;
  outline: 2px auto -webkit-focus-ring-color;
}
.card a:not(.card__link) {
  position: relative;
  z-index: 1;
}
.card h2,
.card h3,
.card h4 {
  margin-bottom: 0.5rem;
}
.card p:not(:last-child) {
  margin-bottom: 1.2rem;
}

a {
  transition: color 0.3s, opacity 0.3s, background 0.3s, text-decoration 0.5s;
  position: relative;
  text-decoration: none;
  color: var(--primary-color);
  text-decoration-color: var(--border-color);
}
a:hover {
  color: var(--body-color);
  text-decoration-color: var(--body-color);
}
svg + a {
  margin-left: 0.5rem;
}
code a {
  color: currentColor;
  font-weight: bold;
}

a:hover:not(.active) {
  opacity: 1;
}